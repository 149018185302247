/* Estilos Generales */
.container {
    margin: 20px auto;
    max-width: 95%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.title {
    font-size: 2rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.filter-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 300px;
    font-size: 1rem;
}

/* Estilo para la tabla */
.publication-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.publication-table th,
.publication-table td {
    padding: 8px 6px;
    text-align: center;
    vertical-align: middle;
}

.publication-table th {
    background-color: #f5f5f5;
    color: #333;
    font-weight: bold;
}

.publication-table td {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
}

/* Truncar contenido en Source y Tipo solo en pantallas grandes */
@media (min-width: 769px) {
    .publication-table td[data-label="Source"],
    .publication-table td[data-label="Tipo"] {
        max-width: 40px; /* Limitar el ancho de las columnas */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .publication-table td[data-label="Source"]:hover,
    .publication-table td[data-label="Tipo"]:hover {
        overflow: visible;
        white-space: normal;
        position: relative;
    }

    .publication-table td[data-label="Source"]:hover::after,
    .publication-table td[data-label="Tipo"]:hover::after {
        content: attr(title); /* Muestra el contenido del atributo title */
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 10;
        font-size: 0.8rem;
    }
}

/* Estilos homogéneos para CID, Source y Tipo */
.publication-table td input[type="text"],
.publication-table td span {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #333;
    font-size: 1rem;
    text-align: center;
    width: auto;
}

/* Selector de Días y Semanas */
.weekday-selector,
.nthweek-selector {
    display: flex;
    justify-content: center;
    gap: 3px;
}

.weekday-selector button,
.nthweek-selector button {
    width: 30px;
    height: 30px;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;
}

.weekday-selector button {
    background-color: #007bff;
    color: white;
}

.weekday-selector button:hover {
    background-color: #0056b3;
}

.nthweek-selector button {
    background-color: #28a745;
    color: white;
}

.nthweek-selector button:hover {
    background-color: #218838;
}

/* Ocultar cabecera y no simplificar en pantallas pequeñas */
@media (max-width: 768px) {
    .publication-table thead {
        display: none;
    }

    .publication-table td[data-label="Source"],
    .publication-table td[data-label="Tipo"] {
        max-width: none;
        overflow: visible;
        text-overflow: clip;
        white-space: normal;
    }

    .publication-table,
    .publication-table tbody,
    .publication-table th,
    .publication-table td,
    .publication-table tr {
        display: block;
        width: 100%;
    }

    .publication-table tr {
        margin-bottom: 20px;
        border-radius: 8px;
        padding: 10px;
    }

    .publication-table td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .publication-table td:last-child {
        border-bottom: none;
    }

    .publication-table td::before {
        content: attr(data-label);
        font-weight: bold;
        color: #333;
        flex-basis: 50%;
        padding-right: 10px;
        text-align: left;
    }

    .publication-table input[type="text"] {
        flex-basis: 50%;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .publication-table input[type="checkbox"] {
        transform: scale(1.2);
    }
}

/* Estilo para el botón de tipo */
.type-button {
    width: auto; /* Cambiado para que se ajuste al texto */
    height: 30px;
    font-size: 0.9rem;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    color: white;
    margin: 0 auto;
    padding: 0 10px; /* Añadido padding para que el texto se vea mejor */
    display: block;
    text-align: right; /* Alinear a la derecha */
}

/* Botón para prensa */
.type-button.prensa {
    background-color: #007bff; /* Color azul para prensa */
}

/* Botón para revistas */
.type-button.revistas {
    background-color: #28a745; /* Color verde para revistas */
}

/* Botón para tipo desconocido */
.type-button.unknown {
    background-color: #dc3545; /* Color rojo para tipo desconocido */
    color: white;
}

/* Hover effect */
.type-button:hover {
    opacity: 0.8;
}

.create-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 10px;
  }
  
  .create-button:hover {
    background-color: #218838;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modal-content input,
  .modal-content select {
    padding: 10px;
    width: calc(100% - 20px);
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-content input[type="checkbox"] {
    width: auto;
  }
  
  .modal-content .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-content .modal-actions button {
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
  }
  
  .modal-content .modal-actions .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .modal-content .modal-actions .save-button:hover {
    background-color: #218838;
  }
  
  .modal-content .modal-actions .cancel-button {
    background-color: #dc3545;
    color: white;
  }
  
  .modal-content .modal-actions .cancel-button:hover {
    background-color: #c82333;
  }
  