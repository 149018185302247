/* Contenedor principal */
.control-panel-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 450px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Título del panel */
.control-panel-title {
  color: #333;
  text-align: center;
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 20px;
}

/* Sección de colas */
.queue-section {
  margin-bottom: 20px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 10px;
}

/* Título de cada sección de cola */
.queue-section-title {
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.3em;
}

/* Contenido de cada sección de cola */
.queue-section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Estilo del contador */
.counter {
  font-size: 1.6em;
  font-weight: bold;
  color: #007bff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.counter-change {
  transform: scale(1.2);
  color: #28a745; /* Color de transición cuando cambia */
}

/* Botones para acciones sin coste (Mover mensajes) */
.move-messages-button {
  background-color: #28a745; /* Color verde */
  color: white;
  padding: 12px 25px;
  margin: 15px 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.move-messages-button i {
  margin-left: 8px;
}

.move-messages-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.move-messages-button:active {
  background-color: #1e7e34;
  transform: translateY(0);
}

/* Botones para borrar cosas (Limpiar colas) */
.queue-action-button {
  background-color: #ffc107;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.queue-action-button i {
  margin-left: 8px;
  font-size: 1.2em;
}

.queue-action-button:hover {
  background-color: #e0a800;
}

.queue-action-button:active {
  background-color: #c69500;
}

/* Botones con coste asociado (Recargar y Scraping) */
.heroku-button {
  background-color: #6762a6;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.heroku-button i {
  margin-left: 8px;
  font-size: 1.2em;
}

.heroku-button:hover {
  background-color: #5b549f;
  transform: translateY(-2px);
}

.heroku-button:active {
  background-color: #4a438b;
  transform: translateY(0);
}

/* Botones deshabilitados */
.heroku-button:disabled,
.queue-action-button:disabled,
.move-messages-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Contenedor de acciones */
.queue-action-container {
  text-align: center;
  margin-top: 30px;
}

/* Título de advertencia para los botones con coste */
.jobs-warning-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  color: #dc3545;
  font-size: 1em;
  margin-top: 25px;
}

/* Estilo del diálogo de confirmación */
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  max-width: 400px;
  width: 90%;
}

.confirm-dialog-content {
  margin-bottom: 20px;
  text-align: center;
}

.confirm-dialog-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #333;
}

.confirm-dialog-content p {
  font-size: 0.9em;
  color: #666;
}

/* Botones en el diálogo de confirmación */
.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 45%;
  margin: 0 5px;
}

.confirm-button {
  background-color: #28a745;
  color: white;
}

.confirm-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover {
  background-color: #c82333;
}

/* Estilo de superposición del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Contenedor deshabilitado */
.control-panel-container.disabled * {
  pointer-events: none;
  opacity: 0.6;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.refresh-icon {
  color: #007bff;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 10px;
}
