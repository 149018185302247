.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
    margin-top: 20px;
}
  
.title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}
  
.loading {
    text-align: center;
    color: #007bff;
    font-size: 18px;
    padding: 20px;
}
  
.error-container {
    text-align: center;
    color: #dc3545;
    font-size: 18px;
    padding: 20px;
    border: 1px solid #dc3545;
    border-radius: 5px;
    background-color: #f8d7da;
    margin: 20px 0;
}
  
.error-message {
    margin: 10px 0;
}
  
.no-publications {
    text-align: center;
    color: #555;
    font-size: 16px;
    padding: 10px;
}
  
.counter-container {
    text-align: center;
    margin-bottom: 20px;
}
  
.counter {
    font-size: 2em;
    font-weight: bold;
    color: #007bff;
}
  
.filter-container {
    text-align: center;
    margin-bottom: 20px;
}
  
.filter-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: calc(100% - 20px);
    box-sizing: border-box;
}
  
.list {
    list-style-type: none;
    padding: 0;
}
  
.list-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 14px;
}
  
.table-header {
    padding: 8px;
    background-color: #007bff;
    color: white;
    text-align: left;
    border-bottom: 2px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.2s ease;
}
  
.table-header:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
  
.table-header:active {
    background-color: #004080;
    transform: scale(0.98);
}
  
.table-cell {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
    .table {
        display: none;
    }

    .list-item {
        display: block;
    }

    .list-item p {
        margin: 5px 0;
    }
}
