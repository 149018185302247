.app-container {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  font-size: 1.2em;
}

.panel-section {
  margin-bottom: 20px;
}

.panel-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.counter {
  font-weight: bold;
}

.panel-item ul {
  list-style: none;
  padding: 0;
}

.panel-item li {
  margin-bottom: 5px;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #f0f0f0;
}

.token-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.token-section input {
  width: 150px;
  padding: 5px;
}

.scraper-button {
  width: 100%;
  padding: 10px;
  background-color: #ffcccc;
  border: 1px solid #ff9999;
}

.scraper-button:hover {
  background-color: #ffb3b3;
}
